import API from "@/services/API.service";
import { Systems } from "@prisma/client";

export async function createSystem(system: Systems): Promise<Systems> {
  const response = await API.post(`/api/system`, {
    body: JSON.stringify(system),
  });

  if (response.success !== true) throw new Error(response.message, { cause: response.cause });

  return response.system;
}

export async function updateSystem(system: Systems): Promise<Systems> {
  const response = await API.put(`/api/system/${system.id}`, {
    body: JSON.stringify(system),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.system;
}

export async function getSystemsByCompanyId(companyId?: string, searchParams?: string): Promise<Systems[]> {
  const response = await API.get(`/api/company/${companyId}/projects${searchParams ? `?${searchParams}` : ""}`);

  if (response.success !== true) throw new Error(response.message);

  return response.systems as Systems[];
}

export async function deleteSystem(systemId: string): Promise<boolean> {
  const response = await API.delete(`/api/system/${systemId}`);

  if (response.success !== true) throw new Error(response.message);

  return true;
}

export async function getSystemUsers(systemId: string) {
  const response = await API.get(`/api/system/${systemId}/users`);

  if (response.success !== true) throw new Error(response.message);

  return response.users;
}

export async function duplicateProject(systemId: string, duplicateData: any) {
  const response = await API.post(`/api/system/${systemId}/duplicate`, {
    body: JSON.stringify(duplicateData),
  });

  if (response.success !== true) throw new Error(response.message);

  return response.newSystem;
}

export async function createProjectShareLink(projectId: string) {
  const response = await API.post(`/api/projects/${projectId}/share`, {
    body: JSON.stringify({}),
  });

  if (response.success !== true) throw new Error(response.message, { cause: response.cause });

  return {
    url: response.url as string,
    expiresAt: response.expiresAt as Number,
    formattedExpiration: response.formattedExpiration as string,
  };
}
