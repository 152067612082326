import { Prisma, Users } from "@prisma/client";
import { User } from "../server/data/UserData.service";

export type PlanPermission = Prisma.PlanPermissionsGetPayload<{
  include: { permission: true; plan: true };
}>;

/**
 * Should be usable both on server and UI.
 * Should not have async methods.
 */
export class PermissionsService {
  user: User | Users;
  throwOnInvalid = true;
  planPermissions: PlanPermission[] = [];

  constructor(user: User | Users, throwOnInvalid = true, planPermissions: PlanPermission[] = []) {
    this.user = user;
    this.throwOnInvalid = throwOnInvalid;
    this.isAdmin = this.isAdmin.bind(this);
    this.isSuperAdmin = this.isSuperAdmin.bind(this);
    this.planPermissions = planPermissions;
  }

  isAdmin(): boolean {
    return this.user.isAdmin === true || this.isSuperAdmin();
  }

  isSuperAdmin(): boolean {
    return this.user.isSuperAdmin === true;
  }

  isCurrentUserOrAdmin(userId: string): boolean {
    return this.user.id === userId || this.isAdmin();
  }

  shouldThrow(result, message) {
    if (!result && this.throwOnInvalid) {
      throw new Error(message);
    }
  }
}
