import dotenv from "dotenv";
import { isNil } from "lodash";
import devConfig from "./dev.config";
import localConfig from "./local.config";
import prodConfig from "./prod.config";
import stagingConfig from "./staging.config";

dotenv.config();

export class EnvironmentService {
  env: string;
  config: Record<string, any>;
  constructor() {
    this.env = process.env.CONFIG_ENVIRONMENT!;

    this.checkEnvironment();

    if (this.env === "development" || this.env === "dev") {
      this.config = devConfig;
    } else if (this.env === "production" || this.env === "prod") {
      this.config = prodConfig;
    } else if (this.env === "staging") {
      this.config = stagingConfig;
    } else {
      this.config = localConfig;
    }
  }

  public checkEnvironment(): void {
    switch (this.env) {
      case "dev":
      case "development":
      case "production":
      case "staging":
      case "local":
      case "preview":
        break;
      default:
        throw new Error("Invalid environment " + this.env);
    }
  }
}

export class UIEnvironmentService extends EnvironmentService {
  HOST: string;
  STRIPE_KEY_FE: string;
  PKEY: string;
  S3_PUBLIC_BUCKET: string;
  GOOGLE_MAPS_API_KEY: string;
  RESIZE_PHOTO_URL_PREFIX: string;
  VIDEO_THUMBNAIL_URL_PREFIX: string;
  FILE_THUMBNAIL_URL_PREFIX: string;
  PUSHER_KEY: string;
  PUSHER_CLUSTER: string;
  SUPPORT_EMAIL_ADDRESS: string;

  constructor() {
    super();

    let domain = isNil(this.config.HOST) ? process.env.VERCEL_URL : this.config.HOST;

    if (!isNil(domain) && !domain.includes("http")) {
      // If the domain is not nil and does not include http, add it
      domain = "https://" + domain;
    }

    this.HOST = domain || "";
    this.STRIPE_KEY_FE = this.config.STRIPE_KEY_FE || "";
    this.PKEY = this.config.PKEY || "";
    this.S3_PUBLIC_BUCKET = this.config.S3_PUBLIC_BUCKET || "";
    this.RESIZE_PHOTO_URL_PREFIX = this.config.RESIZE_PHOTO_URL_PREFIX || "";
    this.VIDEO_THUMBNAIL_URL_PREFIX = this.config.VIDEO_THUMBNAIL_URL_PREFIX || "";
    this.FILE_THUMBNAIL_URL_PREFIX = this.config.FILE_THUMBNAIL_URL_PREFIX || "";
    this.GOOGLE_MAPS_API_KEY = this.config.GOOGLE_MAPS_API_KEY || "";
    this.PUSHER_KEY = this.config.PUSHER_KEY || "";
    this.PUSHER_CLUSTER = this.config.PUSHER_CLUSTER || "";
    this.SUPPORT_EMAIL_ADDRESS = this.config.SUPPORT_EMAIL_ADDRESS || "";
  }
}

export const uiEnvironment = new UIEnvironmentService();
