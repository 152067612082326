"use client";
import { ReactElement, useEffect, useState } from "react";

import useDebounce from "@/hooks/useDebounce";

import { Card, Flex, Input, Space, Spin } from "antd";
import Search from "antd/es/input/Search";
import { isNil } from "lodash";
import { useQueryState } from "next-usequerystate";
import { useSearchParams } from "next/navigation";

type Props = {
  title?: string;
  titleComponent?: ReactElement<any, any> | ReactElement<any, any>[] | null;
  children: ReactElement<any, any> | ReactElement<any, any>[];
  search?: boolean;

  initialSearchValue?: string;
  className?: string;
  titleStyle?: any;
  misc?: ReactElement<any, any> | ReactElement<any, any>[];
  buttons?: ReactElement<any, any> | ReactElement<any, any>[];
  debounce?: number;
  loading?: boolean;
  bordered?: boolean;
};

const CardAndTitle = (props: Props) => {
  const searchParams = useSearchParams();
  const [search, setSearch] = useQueryState("search", { defaultValue: searchParams.get("search") ?? "" });
  const [localSearch, setLocalSearch] = useState(search ?? "");
  const debouncedSearch = useDebounce(localSearch, props.debounce || 400);

  useEffect(() => {
    return () => setLocalSearch("");
  }, []);

  useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch]);

  const cardProps = props.bordered === false ? { bordered: false, className: "p-0", style: { boxShadow: "none" } } : {};

  return (
    <Card {...cardProps}>
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6 ">
        <div className="sm:col-span-6">
          <div className="flex justify-between border-b-2 pb-3 text-xl font-medium text-slate-900">
            <Flex justify="space-between" gap={2} align="center" className="w-full">
              {props.title && <Space>{props.title}</Space>}
              {props.titleComponent ? props.titleComponent : <div></div>}
              <Flex gap={2} justify="flex-end" align="flex-end">
                <Space>
                  {props.misc}
                  {props.search && (
                    <Search
                      className="min-w-40"
                      id="card_search"
                      autoFocus
                      loading={props.loading}
                      value={localSearch}
                      defaultValue={localSearch}
                      onChange={(event) => {
                        const value = event?.target.value;
                        setLocalSearch(value);
                      }}
                    />
                  )}
                  {props.buttons}
                </Space>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
      <div>{props.children}</div>
    </Card>
  );
};

export default CardAndTitle;
