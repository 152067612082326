import { getSystemUsers } from "@/app/apiCalls/projects/projectsCRUD";
import { getCompaniesUsers } from "@/app/apiCalls/user/usersCRUD";
import API from "@/services/API.service";
import { Users } from "@prisma/client";
import { useCallback, useState } from "react";

const useGetCompaniesUsersList = () => {
  const [users, setUsers] = useState<Users[]>([]);
  const [loading, setloading] = useState(false);

  const fetchSystemUsers = useCallback(async (systemId: string) => {
    const users = await getSystemUsers(systemId);

    setUsers(
      users.sort((a: Users, b: Users) => {
        if (!a.email) return -1;
        if (!b.email) return 1;
        return a.email < b.email ? -1 : 1;
      }),
    );
  }, []);

  const fetchUsers = useCallback(async (companyId: string, additionalCompanies: string[] = []) => {
    try {
      setloading(true);
      const users = await getCompaniesUsers(companyId, additionalCompanies);

      setUsers(
        users.sort((a: Users, b: Users) => {
          if (!a.email) return -1;
          if (!b.email) return 1;
          return a.email < b.email ? -1 : 1;
        }),
      );
    } catch (error) {
      throw error;
    } finally {
      setloading(false);
    }
  }, []);

  return {
    users,
    fetchUsers,
    fetchSystemUsers,
    loading,
  };
};

export default useGetCompaniesUsersList;
