import { Input } from "@chakra-ui/react";
import { uiEnvironment } from "@root/config/UIEnvironment.service";
import { useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const AddressAutocompleteInput = (props: any) => {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: uiEnvironment.GOOGLE_MAPS_API_KEY,
  });

  const [value, setValue] = useState<string>(props.defaultValue ? props.defaultValue : "");
  const inputRef = useRef(props.defaultValue ? props.defaultValue : "");

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve) => {
      placesService?.getDetails({ placeId }, (placeDetails) => resolve(placeDetails));
    });
  };

  const clearSuggestions = () => {
    getPlacePredictions("");
  };

  const onPlaceSelection = (placeDetails: any) => {
    const updateObject = {
      country: "",
      streetNumber: "",
      route: "",
      locality: "",
      sublocality: "",
      postalCode: "",
      administrativeAreaLevel1: "",
      administrativeAreaLevel2: "",
    };

    placeDetails?.address_components?.forEach((component: any) => {
      if (component.types.includes("country")) {
        updateObject.country = component.long_name;
      }
      if (component.types.includes("street_number")) {
        updateObject.streetNumber = component.long_name;
      }
      if (component.types.includes("route")) {
        updateObject.route = component.long_name;
      }
      if (component.types.includes("locality")) {
        updateObject.locality = component.long_name;
      }
      if (component.types.includes("sublocality")) {
        updateObject.sublocality = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        updateObject.postalCode = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        updateObject.administrativeAreaLevel1 = component.short_name;
      }
      if (component.types.includes("administrative_area_level_2")) {
        updateObject.administrativeAreaLevel2 = component.long_name;
      }
    });
    const address1 = `${updateObject.streetNumber} ${updateObject.route}`;
    const addressComponents = {
      address1: address1.length > 1 ? address1 : inputRef.current,
      city: updateObject.locality,
      state: updateObject.administrativeAreaLevel1,
      postalCode: updateObject.postalCode,
      country: updateObject.country,
    };

    setValue(address1.length > 1 ? address1 : inputRef.current);
    props.onSelectPlace && props.onSelectPlace(addressComponents);
  };

  return (
    <>
      <Input
        value={value}
        onChange={(e) => {
          inputRef.current = e.target.value;
          setValue(e.target.value);
          getPlacePredictions({ input: e.target.value });
        }}
        onBlur={(e) => {
          setTimeout(() => {
            clearSuggestions();
          }, 100);
        }}
        disabled={props.disabled ?? false}
      />
      {placePredictions.length > 0 && (
        <div className="border border-gray-200 rounded">
          {placePredictions.map((suggestion, index) => {
            return (
              <div
                key={suggestion.place_id}
                className={index === placePredictions.length - 1 ? "p-2" : "p-2 border-b border-gray-200"}
                onClick={async () => {
                  clearSuggestions();
                  const placeDetails = await getPlaceDetails(suggestion.place_id);
                  onPlaceSelection(placeDetails);
                }}
              >
                <p>{suggestion.description}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AddressAutocompleteInput;
