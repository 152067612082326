import { IAPIResponse } from "@/types/apiTypes";

export class APIClass {
  static getSearchParamsObjectFromRequest(req: Request) {
    const urlSearchParams = new URL(req.url).searchParams;
    const searchParamsObject = Object.fromEntries(urlSearchParams.entries());
    return searchParamsObject;
  }

  async get<T>(url: string, options?: RequestInit | undefined) {
    const response = await fetch(url, options);
    return await this.handleResponse<T>(response);
  }

  async post<T>(url: string, options?: RequestInit | undefined) {
    const response = await fetch(url, {
      ...options,
      method: "POST",
    });
    return await this.handleResponse<T>(response);
  }

  async put<T>(url: string, options?: RequestInit | undefined) {
    const response = await fetch(url, {
      ...options,
      method: "PUT",
    });
    return await this.handleResponse<T>(response);
  }

  async delete<T>(url: string, options?: RequestInit | undefined) {
    const response = await fetch(url, {
      ...options,
      method: "DELETE",
    });
    return await this.handleResponse<T>(response);
  }

  private handleResponse<T>(response: Response): Promise<IAPIResponse<T>> {
    if (response.ok) {
      return response.json() as Promise<IAPIResponse<T>>;
    } else {
      return response.json() as Promise<IAPIResponse<T>>;
    }
  }
}

const API = new APIClass();
export default API;

export const baseApiRoutes = {
  equipment: {
    automaticTransferSwitches: "/api/equipment/automatic_transfer_switches",
    dcacInverter: "/api/equipment/dcac_inverters",
    disconnects: "/api/equipment/disconnects",
    evCharger: "/api/equipment/ev_chargers",
    generator: "/api/equipment/generator",
    genericLoad: "/api/equipment/generic_load",
    inverter: "/api/equipment/inverter",
    meter: "/api/equipment/meter",
    junctionBox: "/api/equipment/junction_box",
    motor: "/api/equipment/motor",
    panel: "/api/equipment/panel",
    panelSlot: "/api/equipment/panel_slot",
    transformer: "/api/equipment/transformers",
    solarArray: "/api/equipment/solar_array",
    switchboards: "/api/equipment/switchboard",
    utilityConnection: "/api/equipment/utility_connection",
    variableFrequencyDrive: "/api/equipment/variable_frequency_drive",
    conductors: "/api/equipment/conductors",
    fusedSwitch: "/api/equipment/fused_switch",
    photos: "/api/equipment/photos",
    videos: "/api/equipment/videos",
    fileRecord: "/api/equipment/file_record",
  },
};
