import { decodeJWTToken } from "@/utils/decodeJwt";
import { isNil } from "lodash";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";

export interface ICondoitSession {
  session: Session;
  authStatus: string;
  id_token: string;
}

function tokenValid(token: string | undefined | null): "authenticated" | "unauthenticated" {
  try {
    let valid = true;
    if (isNil(token) || token === "undefined") return "unauthenticated";

    const decodedToken = decodeJWTToken(token) as any;
    valid = decodedToken.exp > Date.now() / 1000;
    return valid ? "authenticated" : "unauthenticated";
  } catch (error) {
    return "unauthenticated";
  }
}

export function useAuthenticator() {
  //This would be the preferred setup so we can have unauthenticated routes
  // const session = useSession({ required: true });
  const session = useSession();
  const authenticationExpired = tokenValid(session?.data?.token?.id_token) === "unauthenticated";

  return {
    session: session,
    authStatus: session.status,
    authenticated: session.status === "authenticated",
    expired: authenticationExpired,
    id_token: session.data?.token.id_token,
  };
}
